import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useGlobal from 'store/store';
import useApi from '../../../hooks/useApi';
//mui
import { Dialog, DialogActions, DialogTitle, Button, Typography, Container, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
//api
import { UpdateRegAPI, addPaymentHana, getGuid } from '../../../api/api'
import { system } from '../../../api/client'
//libs
import { format } from 'date-fns'
//components
import { VBox, HBox } from '../../../assets/SharedComponents';
import { BalanceContext } from 'context/balanceContext';
import { PaymentMessage } from 'Components/Payments/PaymentMessage';
import { CustomBusy } from 'Components/Common/CustomBusy';
import AddParent2Dialog from 'Components/ParentComponents/AddParent2Dialog';
//assets
import verticalRope from '../../../assets/verticalRope.svg'
import payInTribe from '../../../assets/payInTribe.svg'

const useStyles = makeStyles(() => ({
    subTitle: {
        color: '#3a7735 !important',
        textAlign: 'center !important',
        fontSize: '1rem !important',
        margin: '1.4rem !important'
    },
    cardBox: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginTop: '2rem !important'
    },
    splitCard: {
        height: '29rem !important',
        width: '16rem !important',
        justifyContent: 'center !important',
        marginBottom: '1rem !important',
        borderRadius: '50px !important'
    },
    cardTitleAndIcons: {
        justifyContent: 'space-between !important'
    },
    cardTitleAndIcon: {
        alignItems: 'center !important'
    },
    cardTitle: {
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important',
        marginRight: '1rem !important'
    },
    text: {
        color: 'black !important',
        fontSize: '1rem !important',
    },
    boldFont: {
        marginTop: "auto !important",
        fontWeight: 'bold !important',
    },
    paymentsButtons: {
        alignItems: 'center !important',
    },
    greenButton: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginBottom: '1.1rem !important',
        borderRadius: '50px !important',
        width: '16.75rem !important',
    },

    greenBG: {
        backgroundColor: '#3a7735 !important',

    },
    orangeButtonBG: {
        backgroundColor: '#FDBE30 !important',
    },
    whiteButton: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#ffffff !important',
        marginBottom: '1.1rem !important',
        border: '1px solid #3a7735 !important',
        borderRadius: '50px !important',
        width: '16.75rem !important',
    },
    methodText: {
        fontSize: '1rem !important',
        borderRadius: '50px !important',
        textTransform: "capitalize !important",
    },
    whiteText: {
        color: '#ffffff !important'
    },
    greenText: {
        color: '#064601 !important'
    },
    cashText: {
        "&:hover": {
            fontWeight: 'bold !important',
            textDecoration: 'underline !important'
        },
        textDecoration: 'underline !important ',
        color: '#3a7735 !important',
        fontSize: '1rem !important',
        textTransform: "capitalize !important",
    },
    dialog: {
        borderRadius: '25px !important',
        alignItems: 'center !important',
        justifyContent: 'space-around !important',
    },
    RemoveTextFieldNumberArrow: {
        '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
            }
        }
    },
    hiddenXs: {
        '@media (max-width: 576px)': {
            display: "none"
        }
    },
    IdentificationBtn: {
        backgroundColor: "#e1e1e1 !important",
        borderTopLeftRadius: "1rem !important",
        borderBottomLeftRadius: "1rem !important",
        borderBottomRightRadius: "1rem !important",
        borderTopRightRadius: "1rem !important",
        '& .MuiButtonGroup-groupedHorizontal': {
            borderTopLeftRadius: "1rem !important",
            borderBottomLeftRadius: "1rem !important",
            borderBottomRightRadius: "1rem !important",
            borderTopRightRadius: "1rem !important"
        }
    },
    marginBottom1: {
        marginBottom: '1rem !important'
    }, selectedBtn: {
        textTransform: "capitalize !important",
        borderColor: "#3a7735 !important",
        backgroundColor: "#3a7735 !important",
        color: "white !important"
    },
    IdnBtn: {
        backgroundColor: "#e1e1e1  !important",
        color: "#333333 !important",
        border: "none !important",
        textTransform: "capitalize !important"
    },
    width8: {
        width: "8rem",
    },
    width6: {
        width: "6rem",
    },
    buttonGreen: {
        color: 'white !important',
        fontSize: '1.2rem !important',
        fontWeight: 'bold !important',
        borderRadius: '2rem !important',
        boxShadow: '0 8px 9px rgb(125 125 125 / 40%)',
        textTransform: "capitalize !important",
        marginTop: "1rem !important"
    }
}));

const ParentPayment = ({ setLevel, products }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [payByExtraMoneyMassage, setPayByExtraMoneyMassage] = useState(false);
    const [isDivorecd, setIsDivorced] = useState(false);
    const [parent2NotExist, setParent2NotExist] = useState(false);
    const [showCash, setshowCash] = useState(false);
    const [zeroPayment, setZeroPayment] = useState(false);

    const [household] = useGlobal((state) => state.household);
    const [payByExtra, [setPayByExtra]] = useGlobal((state) => state.payByExtra, (actions) => [actions.setPayByExtra]);
    const [payWithAllExtra, [setPayWithAllExtra]] = useGlobal((state) => state.payWithAllExtra, (actions) => [actions.setPayWithAllExtra]);
    const [finalPrice] = useGlobal((state) => state.finalPrice);
    const updateStatus = useApi(UpdateRegAPI);
    const getSapGuid = useApi(getGuid);
    const { balance } = useContext(BalanceContext);

    const today = format(new Date(), 'yyyy-MM-dd');

    //check if there is account balance taht needs to be used
    useEffect(() => {
        let isMounted = true;
        if (products[0]?.Activity === "1") {
            setshowCash(true);
        }
        if (balance.amount < 0) {
            isMounted && setPayByExtraMoneyMassage(true);
        }
        else if (finalPrice === 0) {
            payZeroPayment();
        }
        if (household.Parent1_MaritalStatus === "di" || household.Parent2_MaritalStatus === "di") {
            setIsDivorced(true);
        }
        if (household.Parent1_ID === "" || household.Parent1_ID === null) {
            setParent2NotExist(true);
        }
        if (household.Parent2_ID === "" || household.Parent2_ID === null || household.Parent2_Tel1 === null || household.Parent2_Tel1 === "") {
            setParent2NotExist(true);
        }
        return () => { isMounted = false; }
    }, [])

    //update the request status to "not yet paid"=2
    const updateReqStatus = (e) => {
        products.forEach(async (product) => {
            product.Status_request = "2";
            await updateStatus.requestPromise({ _id: product._id, data: { Status_request: '2' } });
        })
        setLevel(5);
    }

    const payZeroPayment = async () => {
        setZeroPayment(true);
        try {
            const res = await getSapGuid.requestPromise(products, "parent");
            const zeroPaymentDetails =
            {
                Guid: res,
                ParentCardCode: household.CardCode,
                UnitCode: products[0].TribeCode,
                PaymentDate: today,
                PaymentExecutionType: "2",
                CashSum: 0
            }
            if (system !== "prod") {
                zeroPaymentDetails.PaymentPercent = 100;
                zeroPaymentDetails.InvoiceNum = "0";
            }
            const res1 = await addPaymentHana(zeroPaymentDetails);
            if (res1.Status) {
                products.forEach(async (product) => {
                    product.Status_request = "4";
                    product.paid = product.FinalPrice;
                    await updateStatus.requestPromise({
                        _id: product._id,
                        data: {
                            Status_request: '4',
                            paid: '0',
                            Payment_Method: '2'
                        }
                    });
                })
                setLevel(8);
            }
            else {
                enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG });
            }
        } catch (error) {
            enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG });
            console.log(error);
        }
    }

    const approvePayment = async () => {
        setPayByExtra(true);
        if (finalPrice === 0) {
            try {
                const res = await getSapGuid.requestPromise(products);
                const extraPaymentDetails =
                {
                    Guid: res,
                    ParentCardCode: household.CardCode,
                    UnitCode: products[0].TribeCode,
                    PaymentDate: today,
                    PaymentExecutionType: "10"
                }
                if (system !== "prod") {
                    extraPaymentDetails.PaymentPercent = 100;
                    extraPaymentDetails.InvoiceNum = "0";
                }
                const res1 = await addPaymentHana(extraPaymentDetails);
                if (res1.Status) {
                    products.forEach(async (product) => {
                        product.Status_request = "4";
                        product.paid = product.FinalPrice;
                        await updateStatus.requestPromise({ _id: product._id, data: { Status_request: '4', paid: product.FinalPrice, Payment_Method: "10" } });
                    })
                    setLevel(8);
                }
                else {
                    enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG });
                }
            } catch (error) {
                enqueueSnackbar(t("errorOccuredText"), { className: classes.redBG });
                console.log(error);
            }
        }
        else {
            setPayByExtraMoneyMassage(false);
            setPayWithAllExtra(false);
        }
    }

    return (
        <>
            {
                zeroPayment ? <CustomBusy text={t("zeroPaymentReg")} /> :
                    <VBox alignItems="center">
                        {payByExtraMoneyMassage ?
                            <PaymentMessage
                                title={t("paymentFromAccountBalance")}
                                setLevel={setLevel}
                                approvePayment={approvePayment} /> :
                            <>
                                <VBox>
                                    <Typography className={classes.subTitle}>{t('lastStepAndDone')}</Typography>
                                    <Typography className={`${classes.boldFont} ${classes.subTitle}`}>{t('whichPayment')}</Typography>
                                </VBox>
                                <PaymentButton
                                    text={isDivorecd ? t('creditCard1') : t('creditCard')}
                                    products={products}
                                    level={4}
                                    setLevel={setLevel}
                                    children={<CreditCardOutlinedIcon sx={{ color: '#064601' }} />} />
                                {/* divorced user button */}
                                {/* {isDivorecd &&
                                    <PaymentButton text={t('splitCreditCard')} level={6} setLevel={setLevel} children={<AltRouteOutlinedIcon sx={{ color: '#ffffff' }} />} isDivorecd={isDivorecd} household={household} parent2NotExist={parent2NotExist} />
                                } */}
                                {showCash &&
                                    <VBox className={classes.paymentsButtons}>
                                        <HBox className={classes.whiteButton}>
                                            <PaidOutlinedIcon sx={{ color: '#3a7735' }} />
                                            <Button className={classes.cashText} onClick={e => setOpen(true)}>{t('cashOrCheck')}</Button>
                                            <NoteDialog
                                                open={open}
                                                setOpen={setOpen}
                                                updateReqStatus={updateReqStatus} />
                                        </HBox>
                                    </VBox>}
                            </>
                        }
                        {updateStatus.loading && <CustomBusy />}
                    </VBox >}
        </>
    );
}

const PaymentButton = ({ text, children, level, setLevel, isDivorecd = false, household = [], parent2NotExist = false, products = [] }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const updateStatus = useApi(UpdateRegAPI);

    const handleClick = () => {
        if (isDivorecd && parent2NotExist) {
            setShowDialog(true);
            setOpenDialog(true);
        }
        else {
            setLevel(level);
        }
    }

    return (
        <VBox className={classes.paymentsButtons}>
            <HBox className={`${classes.greenButton} ${isDivorecd ? classes.greenBG : classes.orangeButtonBG}`}>
                {children}
                {showDialog && <AddParent2Dialog open={openDialog} setOpen={setOpenDialog} household={household} setLevel={setLevel} level={level} />}
                <Button className={`${classes.methodText} ${isDivorecd ? classes.whiteText : classes.greenText}`} onClick={e => handleClick()}>{text}</Button>
            </HBox>
        </VBox>
    )
}
const NoteDialog = ({ open, setOpen, updateReqStatus }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={e => { setOpen(false) }}
            PaperProps={{
                sx: { width: { xs: "20rem", md: "45rem" }, borderRadius: "50px !important", maxWidth: "max-content", backgroundColor: "#f0f3f0" }
            }}>
            <Container>
                <DialogTitle>
                    <HBox className={classes.dialog} >
                        <DialogActions>
                            <Button onClick={e => { setOpen(false) }} sx={{ marginLeft: { xs: "12rem", md: "25rem" } }} >
                                <CloseOutlinedIcon />
                            </Button>
                        </DialogActions>
                    </HBox>
                </DialogTitle>
                <HBox alignItems={"center"} justifyContent={"space-between"}>
                    <img
                        alt={"..."}
                        src={verticalRope}
                        width="23px"
                        height="142px"
                        className={classes.hiddenXs}
                    />
                    <VBox alignItems={"center"}>
                        <img
                            alt={"..."}
                            width="200px"
                            height="120px"
                            src={payInTribe}
                        />
                        <Typography sx={{ color: "#3a7735", fontWeight: "bold", textAlign: "center" }}>{t('cashOrCheckText')} </Typography>
                        <Typography sx={{ color: "#3a7735", textAlign: "center" }}>{t('cashOrCheckText1')} </Typography>
                        <Typography sx={{ color: "#3a7735", fontSize: "0.875rem", mt: "2rem", textAlign: "center" }}> {t('cashOrCheckText2')}</Typography>
                        <Typography></Typography>
                        <Typography > </Typography>
                        <DialogActions>
                            <Box display="flex" flexDirection="column">
                                <Button className={`${classes.greenButton} ${classes.greenBG} ${classes.methodText} ${classes.whiteText}`} onClick={e => updateReqStatus(e)}>{t('understandMoveForword')}</Button>
                                <Button className={`${classes.whiteButton} ${classes.methodText} ${classes.greenText}`} onClick={e => setOpen(false)}>{t('chooseOtherPay')}</Button>
                            </Box>
                        </DialogActions>
                    </VBox>
                    <img
                        alt={"..."}
                        src={verticalRope}
                        width="23px"
                        height="142px"
                        className={classes.hiddenXs}
                    />
                </HBox>
            </Container>
        </Dialog>
    )
}
export default ParentPayment;