import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGlobal from "../../store/store";
import useApi from "../../hooks/useApi";
//libs
import { format } from 'date-fns'
// Mui
import { Box, Typography, Card, Table, TableHead, TableRow, TableBody, Container, LinearProgress } from "@mui/material";
//api
import { getRefundAPI, getRefundsToFamily } from 'api/api';
//components
import StatusChip from "Components/Common/StatusChip";
import { ChildTabsBox, InactiveChildrenBox, MuiTableCell, MuiTableContainer, NoHistoryBox } from "./sharedHistoryComponents/HistoryComponents";

const ParentRefundsPage = ({ setAppBarTitle }) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(0);
    const [childList, setChildList] = useState([]);
    const [refundList, setRefundsList] = useState([]);
    const getFamilyRefunds = useApi(getRefundsToFamily);
    const getAllRefunds = useApi(getRefundAPI);
    const [household] = useGlobal(state => state.household);

    // get refundList
    useEffect(() => {
        getRefundsList();
    }, []);

    const getRefundsList = async () => {
        let isMounted = true;
        try {
            // const refundsResult = await getFamilyRefunds.requestPromise({ ParentCardCode: household.CardCode });
            // console.log(refundsResult)
            const refunds = await getAllRefunds.requestPromise({ ParentCardCode: household.CardCode });
            if (refunds.length) {
                handleRefundsTexts(refunds);
            }
            isMounted && setRefundsList(refunds.filter((re) => !(re.ItemPurposeType === "4" && !re.Father)));
        } catch (error) {
            console.log(error);
        }
    }
    const selectedChild = childList[currentTab - 1];
    const filteredRefunds = !currentTab ? refundList : refundList.filter(refund => refund.StudentCardCode === selectedChild.CardCode);

    // save childrenlist and init currentTab
    useEffect(() => {
        let isMounted = true;
        if (Object.keys(household).length) {
            const childListHolder = household.ChildrenList.results.filter(ch => ch.PupilStatus === "Y").map(el => ({ name: el.Pupil_FirstName, CardCode: el.CardCode }));
            setChildList(childListHolder);
            const currentTab = childListHolder.findIndex(el => el.CardCode === window.history.state);
            isMounted && currentTab > -1 && setCurrentTab(currentTab);
        }
        return () => { isMounted = false; }
    }, [household]);

    const handleRefundsTexts = (refunds) => {
        const refundActTextMap = {
            "1": t("refundAct1"),
            "2": t("refundAct2"),
        };

        const refundTypeTextMap = {
            "1": t("refundType1"),
            "2": t("refundType2"),
        };

        refunds.forEach(refund => {
            refund.RefundActText = refundActTextMap[refund.RefundAct] || t("noInfo");
            refund.RefundTypeText = refundTypeTextMap[refund.RefundType] || t("noInfo");
            refund.RefundDocTotalText = !!refund.DocTotal ? refund.DocTotal : t("noInfo")
        });
    };

    return (
        <>
            <ChildTabsBox childList={childList} currentTab={currentTab} setCurrentTab={setCurrentTab} familyName={household.Parent1_LastName} />
            {getAllRefunds.loading ? <LinearProgress /> :
                <>
                    {!!childList.length ?
                        !!filteredRefunds.length ?
                            <>
                                <MuiTableContainer sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <Table aria-label="caption table">
                                        <TableHead>
                                            <TableRow key="THrefunds">
                                                {!currentTab && <MuiTableCell>{t('theStudent')}</MuiTableCell>}
                                                <MuiTableCell>{t('activDate')}</MuiTableCell>
                                                <MuiTableCell>{t('activName')} </MuiTableCell>
                                                <MuiTableCell> {t('refundDate')}</MuiTableCell>
                                                <MuiTableCell>{t('refundAct')} </MuiTableCell>
                                                <MuiTableCell>{t('refundType')} </MuiTableCell>
                                                <MuiTableCell>{t('refundTotal')} </MuiTableCell>
                                                <MuiTableCell>{t('status')} </MuiTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredRefunds.map((refund, i) => {
                                                return (
                                                    <>
                                                        <TableRow sx={{ padding: '0 0.5rem' }} key={refund._id}>
                                                            {!currentTab && <MuiTableCell><Typography variant="responsiveTextSize">{refund.Pupil_FirstName}</Typography></MuiTableCell>}
                                                            <MuiTableCell><Typography variant="responsiveTextSize">{format(new Date(refund.StartDateD), 'dd/MM/yy')}</Typography></MuiTableCell>
                                                            <MuiTableCell><Typography fontWeight='600' variant="responsiveTextSize">{refund.ActivName}</Typography></MuiTableCell>
                                                            <MuiTableCell><Typography variant="responsiveTextSize">{!!refund.DocFormatedDate ? format(new Date(refund.DocFormatedDate), 'dd/MM/yy') : t("noInfo")}</Typography></MuiTableCell>
                                                            <MuiTableCell><Typography variant="responsiveTextSize">{refund.RefundActText}</Typography></MuiTableCell>
                                                            <MuiTableCell><Typography variant="responsiveTextSize">{refund.RefundTypeText}</Typography></MuiTableCell>
                                                            <MuiTableCell><Typography variant="responsiveTextSize">{refund.RefundDocTotalText}</Typography></MuiTableCell>
                                                            <MuiTableCell sx={{ maxWidth: "7rem" }}>
                                                                <Box display='flex' flexDirection="column">
                                                                    <StatusChip status={refund.Status_request} size='small' />
                                                                </Box>
                                                            </MuiTableCell>
                                                        </TableRow>
                                                    </>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </MuiTableContainer>
                                <Container sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    {filteredRefunds.map((refund, i) => {
                                        return (
                                            <RefundMobileCard
                                                refund={refund}
                                                key={refund._id} />
                                        )
                                    })}
                                </Container>
                            </>
                            : <NoHistoryBox text={t("noHistoryRefund")} />
                        : <InactiveChildrenBox setAppBarTitle={setAppBarTitle} />}
                </>}
            {getAllRefunds.error && <NoHistoryBox text={t("noHistoryRefund")} />}
        </>
    )
}
const RefundMobileCard = ({ refund }) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ padding: '0 0.2rem', margin: "1rem 0", boxShadow: "none", borderBottom: "1px solid black", borderRadius: "0px" }}>
            <Box display='flex' flexDirection="row" alignItems='center' justifyContent='flex-start'>
                <Box display='flex' flexDirection="column" padding='1rem 0.5rem' alignItems='flex-start'>
                    <Box display='flex' flexDirection="column" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{refund.Pupil_FirstName}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{refund.ActivName}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{`${t('activDate')} :`}</Typography>
                        <Typography variant="responsiveTextSize">{format(new Date(refund.StartDateD), 'dd/MM/yy')}</Typography>
                    </Box>
                </Box>
                <Box display='flex' flexDirection="column" padding='1rem 0.5rem' alignItems='flex-start'>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{`${t('refundDate')} :`}</Typography>
                        <Typography variant="responsiveTextSize">{!!refund.DocFormatedDate ? format(new Date(refund.DocFormatedDate), 'dd/MM/yy') : t("noInfo")}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{`${t('refundType')} :`}</Typography>
                        <Typography variant="responsiveTextSize">{refund.RefundTypeText}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{`${t('refundAct')} :`}</Typography>
                        <Typography variant="responsiveTextSize">{refund.RefundActText}</Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}
export default ParentRefundsPage;