import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useApi from '../hooks/useApi';
import useGlobal from "../store/store";
//libs
import { format } from 'date-fns'
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import PDFMerger from 'pdf-merger-js/browser';
// Mui
import { Table, Box, TableBody, TableCell, TableContainer, TableHead, TextField, TableRow, Hidden, Typography, CircularProgress, Tooltip, Autocomplete, IconButton, LinearProgress, styled, Link } from "@mui/material";
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { createFilterOptions } from "@mui/material/Autocomplete";
//data
import { permanentInfo } from "data/permanentInfo";
//api
import { getActivitiesReport, getReportFile, getPeriods } from '../api/api';
//components
import { CustomBusy } from "Components/Common/CustomBusy";
import StatusChip from "Components/Common/StatusChip";
import MultiSelect from "Components/Common/MultiSelect";
//assets
import NoParentApprove from '../assets/NoParentApprove.svg'

export const InputStyle = styled(TextField)(({ theme, ...props }) => ({
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        width: props.type === "period" ? '12vw' : '17vw',
        minWidth: '10rem',
        borderRadius: '25px',
        transition: "all 200ms ease-in-out",
        marginBottom: '5px',
        background: "#fff",
        minHeight: '2.5rem',
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
        ":after,:before": {
            borderBottom: 'none !important',

        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
            borderWidth: '1.5px',
        },

        [theme.breakpoints.down('sm')]: {
            paddingRight: '28px !important',
            width: '25vw',
            "& .MuiInputBase-input": {
                fontSize: '0.6rem'
            },
        },
    }
}));
export const MuiTableContainer = styled(TableContainer)(({ theme }) => ({
    maxWidth: '70rem', margin: '2rem auto',
    [theme.breakpoints.down('sm')]: {
        margin: '1rem auto'
    }
}));
export const MuiTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: 'center',
    "&.MuiTableCell-head": {
        fontWeight: "bold",
        fontSize: '1rem',

    },

    [theme.breakpoints.down('sm')]: {
        fontSize: '11px',
        padding: '0.3rem',
        "&.MuiTableCell-body": {
            padding: '0 !important'
        }

    }
}));
const MuiIconButton = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '1em',
        margin: '0 0.2em'
    }
}));

const ActivitiesReport = ({ tribe, factoriesRequests }) => {
    const { t } = useTranslation();
    const getActivitiesReportApi = useApi(getActivitiesReport);
    const periods = useApi(getPeriods);

    const [regReqs, setRegReqs] = useState([]);
    const [pagesCount, setPagesCount] = useState(0);
    const [statusFilter, setFilter] = useState([]);
    const [mergedPdfUrl, setMergedPdfUrl] = useState(null);
    const [reportLoading, setReportLoading] = useState(false);
    const [parentReportLoading, setParentReportLoading] = useState(false);

    const [activCode, setActivCode] = useGlobal((state) => state.activCode, (actions) => actions.setActivCode);
    const [openActivities] = useGlobal((state) => state.openActivities);
    const [activitiesList, setActivitiesList] = useGlobal((state) => state.activitiesList, (actions) => actions.setActivitiesList);
    const [activ, setActiv] = useState(openActivities.filteredFactories?.find(el => el.ActivCode === activCode) || null);
    const [periodsList, setPeriodsList] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState({ PeriodCode: "0", PeriodName: "לא נמצאה תקופה" });

    const inputRef = useRef(null);
    // filter current list by status
    const filteredActivities = !statusFilter.length ? regReqs : regReqs.filter(reg => !!statusFilter.find(el => el.value === reg.Status_request));

    const STATUS_CODES = [
        { value: '2', label: t('notpayed') },
        { value: '3', label: t('partialPay') },
        { value: '4', label: t('registered') },
        { value: '5', label: t('cancelled') }
    ];

    useEffect(() => {
        let isMounted = true;
        isMounted && handlePeriods();
        return () => { isMounted = false }
    }, []);

    useEffect(() => {
        let isMounted = true;
        !!activ && getOpenRequsts(isMounted);
        return () => { isMounted = false }
    }, [activ, currentPeriod]);

    useEffect(() => {
        if (openActivities.filteredFactories?.length === 0) {
            setRegReqs([]);
            setActiv(null);
        }
    }, [openActivities]);

    useEffect(() => {
        mergedPdfUrl && window.open(mergedPdfUrl);
    }, [mergedPdfUrl]);

    const handlePeriods = async () => {
        const periodApi = await periods.requestPromise();
        let periodsArray = [];
        if (periodApi.d.results.length >= 2) {
            periodsArray = periodApi.d.results.slice(-2);
        } else {
            periodsArray = periodApi.d.results;
        }
        const noPeriodObject = { PeriodCode: "0", PeriodName: "לא נמצאה תקופה" };
        const choosenPeriod = periodsArray.length ? periodsArray[periodsArray.length - 1] : noPeriodObject;
        setCurrentPeriod(choosenPeriod);
        setPeriodsList(periodsArray);
    }
    const getOpenRequsts = async (isMounted) => {
        let activCodeFilter = {};
        if (activ.ActivCode !== "select-all") {
            activCodeFilter = { ActivCode: activ.ActivCode };
        }
        const data = await getActivitiesReportApi.requestPromise({ ...activCodeFilter, TribeCode: tribe?.UnitCode || '', Status_request: { $in: ["2", "3", "4", "5", "7"] }, PeriodCode: currentPeriod.PeriodCode });
        isMounted && setRegReqs(data);
    }
    const printDocument = async () => {
        try {
            setParentReportLoading(true);
            const merger = new PDFMerger();
            const files = [];

            const addEls = inputRef.current.querySelectorAll('.showOnPrint');

            // Set a reasonable height for each page
            const pageHeight = 800;

            for (let i = 0; i < addEls.length; i += pageHeight) {
                // Display a chunk of elements
                for (let j = i; j < i + pageHeight && j < addEls.length; j++) {
                    addEls[j].style.display = 'table-cell';
                }

                // Clone the doc
                const clonedElement = inputRef.current.cloneNode(true);

                // Hide content again permanently
                for (let j = i; j < i + pageHeight && j < addEls.length; j++) {
                    addEls[j].style.display = 'none';
                }

                // Ensure that all elements are visible by scrolling
                const scrollHeight = inputRef.current.scrollHeight;
                inputRef.current.style.height = scrollHeight + 'px';

                // Add the cloned doc to the DOM for printing, then remove it
                document.body.appendChild(clonedElement);
                const canvas = await html2canvas(clonedElement, { scale: 1 });
                document.body.removeChild(clonedElement);

                // Reset the height after capturing the canvas
                inputRef.current.style.height = '';

                // Make pdf from html and add to array of pdfs
                const imgData = canvas.toDataURL('img/png');
                const pdf = new JsPDF('p', 'mm', 'a4', true);
                pdf.addImage(imgData, 'JPEG', 0, 10, 208, canvas.height * 208 / canvas.width);
                files.push(pdf.output('blob')); // Use 'blob' to get the PDF as a Blob
            }

            for (const file of files) {
                await merger.add(file);
            }

            setPagesCount(files.length);
            merger.save("דוח רשומים לפעילויות");
        } catch (error) {
            console.log(error);
        } finally {
            setParentReportLoading(false);
        }
    }

    const printDocs = async () => {
        setReportLoading(true);
        const pdfUrls = [];
        filteredActivities.forEach(el => {
            const fileObj = el?.ParentStatement?.signatureFile;
            !!fileObj && pdfUrls.push(fileObj.url);
        })
        generatePDF(pdfUrls);
    }

    const generatePDF = async (pdfUrls) => {
        try {
            const batchSize = 15,
                batches = [];
            setPagesCount(Math.max(1, Math.ceil(pdfUrls.length / batchSize)));
            // Split pdfUrls into batches
            for (let i = 0; i < pdfUrls.length; i += batchSize) {
                const batch = pdfUrls.slice(i, i + batchSize);
                batches.push(batch);
            }
            // Process each batch
            for (let batchIndex = 0; batchIndex < batches.length; batchIndex++) {
                const batch = batches[batchIndex];
                const response = await getReportFile(batch); // Fetch the merged PDF from the server
                const mergedPdfBytes = new Uint8Array(response.data); // Convert to Uint8Array
                // Create a new PDFMerger for each batch
                const merger = new PDFMerger();
                // Merge the current batch into the PDFMerger
                await merger.add(mergedPdfBytes);
                // Save the current batch with a unique filename
                merger.save(`טפסי הצהרת הורים_${batchIndex * batchSize + 1}-${(batchIndex + 1) * batchSize}.pdf`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setReportLoading(false);
        }
    }

    return (
        <MuiTableContainer >
            <Box display='flex' alignItems='center' sx={{ padding: { xs: '0 1rem', lg: '0' } }}>
                <MuiIconButton color="inherit" ><FactCheckOutlinedIcon /></MuiIconButton>
                <Typography ml='4px' variant="title">{`${t('regReportTitle')} (${filteredActivities.length})`}</Typography>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-end' justifyContent="flex-end" paddingRight="1rem">
                {/* <Link component="button" sx={{ textDecoration: 'underline', cursor: !filteredActivities.length || reportLoading ? "default" : "pointer" }} underline="always" onClick={printDocument} disabled={!filteredActivities.length || reportLoading}>
                    <Typography fontWeight='bold' ml='4px' color={!filteredActivities.length || reportLoading ? "#797979" : "primary"} >{t('printReport')}</Typography>
                </Link> */}

                <Link component="button" sx={{ textDecoration: 'underline', cursor: !filteredActivities.length || reportLoading ? "default" : "pointer" }} underline="always" onClick={printDocs} disabled={!filteredActivities.length || reportLoading}>
                    <Tooltip title={t("takeAWhile")}>
                        <Typography fontWeight='bold' ml='4px' color={!filteredActivities.length || reportLoading ? "#797979" : "primary"} >{t('printParentStatments')}</Typography>
                    </Tooltip>
                </Link>
            </Box>
            <Box display='flex' flexWrap='wrap' width={'100%'} justifyContent="space-between" sx={{ padding: { xs: '0 1rem', lg: '0' } }}>
                <Box display='flex' >
                    <Autocomplete
                        noOptionsText={t('noPeriodFound')}
                        size="small"
                        clearIcon={false}
                        onChange={(e, value) => { setCurrentPeriod(value); }}
                        getOptionLabel={(option) => `${option.PeriodName}`}
                        isOptionEqualToValue={(option, anotherOption) => option.PeriodCode === anotherOption.PeriodCode}
                        options={periodsList}
                        value={currentPeriod}
                        renderInput={(params) =>
                            <InputStyle
                                type="period"
                                {...params}
                                label={t('choosePeriodActiv')}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {periods.loading && <CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </>)
                                }}
                            />}
                        renderOption={(props, pr) => (
                            <li key={pr.PeriodCode} {...props} ><Box key={pr.PeriodCode}><Typography variant="responsiveTextSize">{pr.PeriodName}</Typography></Box></li>
                        )}
                        sx={{ margin: '0.9rem 0rem' }} />

                    <Autocomplete
                        noOptionsText={t('noActivs')}
                        size="small"
                        clearIcon={false}
                        loading={factoriesRequests.loading}
                        onChange={(e, value) => { setActiv(value); }}
                        getOptionLabel={(option) => `${option.ActivName}`}
                        isOptionEqualToValue={(option, anotherOption) => option.ActivCode === anotherOption.ActivCode || option.ActivCode}
                        options={activitiesList}
                        renderInput={(params) => <InputStyle  {...params} label={t('chooseActiv')} />}
                        value={activ}
                        renderOption={(props, activ) => (
                            <li key={activ._id} {...props} ><Box key={activ._id}><Typography variant="responsiveTextSize">{activ.ActivName}</Typography></Box></li>
                        )}
                        filterOptions={(options, params) => {
                            const filter = createFilterOptions();
                            const filtered = filter(options, params);
                            return [{ ActivName: t('allActivs'), ActivCode: "select-all" }, ...filtered];
                        }}
                        sx={{ margin: '0.9rem' }} />
                    <MultiSelect
                        items={STATUS_CODES}
                        onChange={setFilter}
                        label={t('chooseStatus')}
                        selectAllLabel={t('allStatus')} />
                </Box>
            </Box>
            {reportLoading && <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                <Typography sx={{ alignItems: "center", justifyContent: "center", fontSize: "1.2rem", color: "black", fontWeight: "bold" }}>{t("makeReport")}</Typography>
                <CustomBusy text={`${t("pagesCounter")} ${pagesCount}`} />
            </Box>}
            {parentReportLoading && <CustomBusy text={t("waitTillDownload")} />}
            {getActivitiesReportApi.loading && <LinearProgress />}
            {!!filteredActivities.length ? <Table ref={inputRef} aria-label="caption table">
                <TableHead>
                    <TableRow>
                        <MuiTableCell>{t('studentName')}</MuiTableCell>
                        <MuiTableCell>{t('regDate')} </MuiTableCell>
                        <Hidden smDown>
                            <MuiTableCell>{t('payed')} </MuiTableCell>
                            <MuiTableCell> {t('leftToPay')}</MuiTableCell>
                            <MuiTableCell>{t('shortPayType')} </MuiTableCell>
                        </Hidden>
                        <MuiTableCell>{t('status')}</MuiTableCell>
                        <MuiTableCell>{t('prefs')}</MuiTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!getActivitiesReportApi.loading && filteredActivities.map((regRequest) => <RgeReqCell request={regRequest} key={regRequest._id} />)}
                </TableBody>
            </Table> :
                <Box display="flex" flexDirection="column" height={'100%'} paddingBottom={'4rem'} alignItems={'Center'}>
                    <img
                        alt={"..."}
                        src={NoParentApprove}
                        width="300px"
                        height="300px"
                    />
                    <Typography sx={{ color: "#3a7735", fontSize: "1.3rem", fontWeight: "bold", textAlign: "center" }} >{t("noParentApprove")}</Typography>
                </Box>}
        </MuiTableContainer >
    );
}
const RgeReqCell = ({ request }) => {
    const { t } = useTranslation();
    const leftToPay = request.FinalPrice - request.paid > 0 ? +(request.FinalPrice - request.paid).toFixed(0) : 0
    const createDate = format(new Date(request.updatedAt), 'dd/MM/yy');
    const prefFlags = {
        food: !!request?.ParentStatement?.pupilFoodPref?.length,
        allergies: !!request?.ParentStatement?.pupilAllergyList?.length,
        diseases: !!request?.ParentStatement?.pupilDiseasesList?.length,

    }
    const foodPrefToolTip = prefFlags.food ? request?.ParentStatement?.pupilFoodPref.join() : t('noFoodPref')
    const allergiesToolTip = prefFlags.allergies ? request?.ParentStatement?.pupilAllergyList.join() : t('noAlergies');
    const diseasesToolTip = prefFlags.diseases ? request?.ParentStatement?.pupilDiseasesList.join() : t('noMedicPref');
    const diseasesToolTipEl =
        (<>
            <Typography fontSize='0.7rem' color="inherit">{diseasesToolTip}</Typography>
            {request?.ParentStatement?.emerDrName && <Typography fontSize='0.7rem' color="inherit">{`${t('drNameShort')}: ${request?.ParentStatement?.emerDrName}`}</Typography>}
        </>)
    return (
        <TableRow key={request.id}>
            <MuiTableCell ><Typography fontWeight='600' variant="responsiveTextSize">{`${request.Pupil_FirstName} ${request.Pupil_lastName}`}</Typography></MuiTableCell>
            <MuiTableCell ><Typography variant="responsiveTextSize">{createDate}</Typography></MuiTableCell>
            <Hidden smDown>
                <MuiTableCell><Typography sx={{ direction: 'rtl' }}>{`\u20AA${(+request.paid).toFixed()}`}</Typography></MuiTableCell>
                <MuiTableCell><Typography sx={{ direction: 'rtl' }}>{`\u20AA${leftToPay}`}</Typography></MuiTableCell>
                <MuiTableCell><Typography>{request?.Payment_Method ? permanentInfo.paymentMethods[request.Payment_Method] : '-'}</Typography></MuiTableCell>
            </Hidden>
            <MuiTableCell>
                <StatusChip status={request.Status_request} />
            </MuiTableCell>
            <MuiTableCell data-html2canvas-ignore="true" >
                <IconInfoCell title={foodPrefToolTip} ><RestaurantIcon htmlColor={prefFlags.food ? "#4f884a" : "#dbdbdb"} /></IconInfoCell>
                <IconInfoCell title={allergiesToolTip} ><CoronavirusOutlinedIcon htmlColor={prefFlags.allergies ? "#93272c" : "#dbdbdb"} /></IconInfoCell>
                <IconInfoCell title={diseasesToolTipEl} ><MedicalServicesOutlinedIcon htmlColor={prefFlags.diseases ? "#93272c" : "#dbdbdb"} /></IconInfoCell>
                <SwimToolTip state={request?.ParentStatement?.swimPermission} />
            </MuiTableCell>
            <MuiTableCell className="showOnPrint" sx={{ display: "none" }}>
                <Box display='flex' justifyContent='center'>
                    <Box display='flex' flexDirection='column' alignItems='flex-start'>
                        {prefFlags.food && < Typography >{`${t('foodPreference')} : ${foodPrefToolTip}`}</Typography>}
                        {prefFlags.allergies && <Typography >{`${t('allergies')} : ${allergiesToolTip}`}</Typography>}
                        {prefFlags.diseases && <Typography >{`${t('diseases')} : ${diseasesToolTipEl}`}</Typography>}
                        <SwimToolTip print={true} state={request?.ParentStatement?.swimPermission} />
                    </Box>
                </Box>
            </MuiTableCell>
        </TableRow>
    )
}
const IconInfoCell = ({ children, title, }) => {
    return (
        <Tooltip enterDelay={100} title={title} arrow>
            <MuiIconButton>
                {children}
            </MuiIconButton>
        </Tooltip>
    )
}
const SwimToolTip = ({ state = "N", print = false }) => {
    const { t } = useTranslation()
    let texts = [t('approvSwim'), t('mySunCanSwim'), t('approvNightSwim')];
    let cases = {
        'N': { flags: [false, false, false], color: 'error' },
        'Y': { flags: [true, true, true], color: 'success' },
        'D': { flags: [true, true, false], color: 'warning' },
        'H': { flags: [true, false, true], color: 'warning' },
        'Z': { flags: [true, false, false], color: 'warning' },
    }
    const currentCase = cases[state]
    const allowAtLeastOne = currentCase['flags'].some(el => !!el);
    const toolTipTitle = (
        <Box display='flex' flexDirection='column'>
            {allowAtLeastOne ?
                <>{currentCase.flags.map((statement, index) =>
                    <Box display='flex' alignItems='center' key={index}>
                        <IconButton size="small" >
                            {statement ? <DoneIcon color="success" /> : <ClearIcon color='error' />}
                        </IconButton>
                        <Typography fontSize='0.7rem'>{texts[index]}</Typography>
                    </Box>
                )}                </>
                : <Typography fontSize='0.7rem'>{permanentInfo.swimPermission[state]}</Typography>}
        </Box>
    )
    if (print)
        return allowAtLeastOne ?
            <>{currentCase.flags.map((statement, index) =>
                <Box display='flex' alignItems='center' key={index}>
                    {statement ? <DoneIcon color="success" /> : <ClearIcon color='error' />}
                    <Typography>{texts[index]}</Typography>
                </Box>
            )}</>
            : <Typography sx={{ paddingRight: '1rem' }}>{t('disagreeSwim')}</Typography>
    else
        return (
            <IconInfoCell title={toolTipTitle} ><PoolOutlinedIcon color={currentCase.color} /></IconInfoCell>)
}
export default ActivitiesReport;