import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "raviger";
import { useTranslation } from "react-i18next";
import useGlobal from "../../store/store";
import useApi from "hooks/useApi";
//libs
import { format } from 'date-fns'
// Mui
import { Table, TableBody, Box, Link, TableCell, TableHead, TableRow, Typography, Select, MenuItem, Container, Card, LinearProgress } from "@mui/material";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
//data
import { permanentInfo } from "data/permanentInfo";
//api
import { getHistoryRegAPI } from 'api/api';
//components
import { BottomCancelReg, ContactCancelReg, TopCancelReg, BottomCantCancelReg, ContactCantCancelReg } from 'Components/Common/CancelRegPopups';
import { InactiveChildrenBox, NoHistoryBox, MuiTableCell, MuiTableContainer, ChildTabsBox } from "./sharedHistoryComponents/HistoryComponents";
import { MessageBox } from "Components/Common/MessageBox";
import StatusChip from "Components/Common/StatusChip";

const ParentRegsHist = ({ removeHouseholdSelection, setIsUpdated, isUpdated, setAppBarTitle }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);
    const [sorter, setSorter] = useState(1);
    const [childList, setChildList] = useState([]);
    const [allFamilyActivites, setAllFamilyActivites] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState([]);
    const [noActivites, setNoActivites] = useState(false);
    const [ready, setReady] = useState(false);

    const [messageBoxState, setMessageBox] = useState({ open: false });
    const [household] = useGlobal(state => state.household);

    const getHistoryReg = useApi(getHistoryRegAPI);

    const statusLabel = {
        "2": t("unpaidYet"),
        "3": t("partialPay"),
        "4": t("registered"),
        "5": t("cancelled"),
        "6": t("refunded"),
        "7": t("payWaitToReg"),
        "99": t("notReg"),
        "100": t("regularReg")
    };

    useEffect(() => {
        getRegistrations();
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (Object.keys(household).length) {
            const childListHolder = household.ChildrenList.results.filter(ch => ch.PupilStatus === "Y").map(el => ({ name: el.Pupil_FirstName, CardCode: el.CardCode }));
            setChildList(childListHolder);
            const currentTab = childListHolder.findIndex(el => el.CardCode === window.history.state);
            isMounted && currentTab > -1 && setCurrentTab(currentTab);
        }
        return () => { isMounted = false }
    }, [household, allFamilyActivites]);

    const getRegistrations = async () => {
        try {
            const data = await getHistoryReg.requestPromise(household.CardCode);
            setAllFamilyActivites(data);
            handleFiltersAndTabs();
        } catch (error) {
            setAllFamilyActivites([]);
            enqueueSnackbar(error?.message || t("Error"), { variant: "Error" });
        }
    }

    useEffect(() => {
        handleFiltersAndTabs();
    }, [allFamilyActivites, currentTab]);


    const handleFiltersAndTabs = () => {
        let filteredRegs = [];
        if (allFamilyActivites.length && childList.length) {
            if (!currentTab) {
                filteredRegs = allFamilyActivites;
                let showActives = 0;
                allFamilyActivites.forEach(stat => { showActives += stat.regs.length; });
                setNoActivites(Boolean(showActives));

            } else {
                const selectedChild = childList[currentTab - 1];
                filteredRegs = allFamilyActivites.map(stat => {
                    return {
                        'status': stat.status,
                        'regs': stat.regs.filter(reg => reg.StudentCardCode === selectedChild.CardCode)
                    };
                })
                let showActives = 0;
                allFamilyActivites.forEach(stat => { showActives += stat.regs.filter((reg) => reg.StudentCardCode === selectedChild.CardCode).length; });
                setNoActivites(Boolean(showActives));

            }
        }
        setFilteredActivities(filteredRegs);
        setReady(true);
    }

    useEffect(() => {
        handleSort();
    }, [sorter]);


    const unpaidCancelMessage = () => {
        const closeMsgBox = () => {
            setMessageBox({ open: false });
            setIsUpdated(isUpdated + 1);

        };

        setMessageBox({
            open: true,
            fullWidth: false,
            maxWidth: "5rem",
            title:
                <TopCancelReg closeMsgBox={closeMsgBox} />,
            content:
                <ContactCancelReg />,
            actions:
                <BottomCancelReg closeMsgBox={closeMsgBox} />
        });
    };
    const paidCancelMessage = () => {
        const closeMsgBox = () => {
            setMessageBox({ open: false });
        };

        const navtoContactForm = () => {
            closeMsgBox();
            navigate("/contact")
        }

        setMessageBox({
            open: true,
            fullWidth: false,
            maxWidth: "5rem",
            title:
                <TopCancelReg closeMsgBox={closeMsgBox} />,
            content:
                <ContactCantCancelReg />,
            actions:
                <BottomCantCancelReg closeMsgBox={closeMsgBox} navtoContactForm={navtoContactForm} />
        });
    };
    const handleSort = () => {
        let sortedArray = [];
        if (!currentTab) {
            sortedArray = allFamilyActivites;
        } else {
            const selectedChild = childList[currentTab - 1];
            sortedArray = allFamilyActivites.map(stat => {
                return {
                    ...stat,
                    regs: stat.regs.filter(reg => reg.StudentCardCode === selectedChild.CardCode)
                };
            });
        }
        switch (sorter) {
            case 1:
                sortedArray.forEach((element) => {
                    element.regs?.sort((a, b) => new Date(b.StartDateD) - new Date(a.StartDateD));
                });
                break;
            case 2:
                sortedArray.sort((a, b) => {
                    return Number(b.status) - Number(a.status);
                });
                break;
            default:
                break;
        }
        setFilteredActivities(sortedArray);
    };
    const onGenatareLink = (link) => {
        navigator.clipboard.writeText(link);
        const key = enqueueSnackbar(t('copiedLink'), { variant: "success" });
        setTimeout(() => { closeSnackbar(key) }, 1500)
    }
    const onCancelRegistration = (row) => {
        if (row.Status_request === "2") { //unpaid
            try {
                removeHouseholdSelection(row);
                getRegistrations();
                unpaidCancelMessage();
            } catch (error) {
                enqueueSnackbar(error?.message || t("Error"), { variant: "warning" });
            }
        } else {
            try {
                paidCancelMessage();
            } catch (error) {
                enqueueSnackbar(error?.message || t("Error"), { variant: "warning" });
            }
        }
    }
    return (
        <>
            <ChildTabsBox childList={childList} currentTab={currentTab} setCurrentTab={setCurrentTab} familyName={household.Parent1_LastName} />
            {!ready ? <LinearProgress /> : <>
                <SortLine setSorter={setSorter} disabled={!noActivites} />
                {!!childList.length ?
                    noActivites ?
                        <>
                            <MuiTableContainer sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            {!currentTab && <MuiTableCell>{t('theStudent')}</MuiTableCell>}
                                            <MuiTableCell>{t('activDate')}</MuiTableCell>
                                            <MuiTableCell>{t('regDate')} </MuiTableCell>
                                            <MuiTableCell>{t('activName')} </MuiTableCell>
                                            <MuiTableCell> {t('payType')}</MuiTableCell>
                                            <MuiTableCell>{t('status')} </MuiTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredActivities.map((list, i) => {
                                            return (
                                                <>
                                                    {!!list.regs.length && <TableRow key={i}>
                                                        <TableCell>
                                                            <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold" }} >{`${t('status')}: ${statusLabel[list.status]}`}</Typography>
                                                        </TableCell>
                                                    </TableRow>}
                                                    {list.regs?.map((regRequest, i) => {
                                                        return (
                                                            <>
                                                                {regRequest.PaymentLink &&
                                                                    <TableRow key={`${regRequest._id}${i}`}>
                                                                        <TableCell>
                                                                            <Box display='flex' flexDirection="row" mt='0.5rem'>
                                                                                <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold" }} >{`${t('linkThatMadeIn')}- ${format(new Date(regRequest.createLinkDate), 'dd/MM/yy')} `}</Typography>
                                                                                <Link component='button' onClick={e => onGenatareLink(regRequest.PaymentLink)} sx={{ color: '#3a7735 !important', fontFamily: "Alef,sans-serif !important", marginLeft: "0.3rem" }} >
                                                                                    <ContentCopyOutlinedIcon sx={{ color: '#3a7735' }} />
                                                                                    <Typography lineHeight='1.5rem' variant="responsiveTextSize" > {t("copyLink")}</Typography>
                                                                                </Link>
                                                                            </Box>
                                                                        </TableCell>
                                                                    </TableRow>}
                                                                <RgeReqCell request={regRequest} onCancelRegistration={onCancelRegistration} currentTab={currentTab} key={regRequest._id} />
                                                            </>)
                                                    })}
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </MuiTableContainer>
                            <Container sx={{ display: { xs: 'block', sm: 'none' } }}>
                                {filteredActivities.map((list, i) => {
                                    return (
                                        <>
                                            {!!list.regs.length && <TableRow key={i}>
                                                <TableCell>
                                                    <Typography sx={{ fontSize: "0.875rem", fontWeight: "bold" }} >{`${t('status')}: ${statusLabel[list.status]}`}</Typography>
                                                </TableCell>
                                            </TableRow>}
                                            {list.regs?.map((regRequest, i) => {
                                                return (
                                                    <>
                                                        {regRequest.PaymentLink &&
                                                            <TableRow key={`${regRequest._id}${i}`}>
                                                                <TableCell>
                                                                    <Box display='flex' flexDirection="row" mt='0.5rem'>
                                                                        <Typography sx={{ fontSize: "0.875rem", fontWeight: "bold" }} >{`${t('linkThatMadeIn')}- ${format(new Date(regRequest.createLinkDate), 'dd/MM/yy')} `}</Typography>
                                                                        <Link component='button' onClick={e => onGenatareLink(regRequest.PaymentLink)} sx={{ color: '#3a7735 !important', fontFamily: "Alef,sans-serif !important", marginLeft: "0.3rem" }} >
                                                                            <ContentCopyOutlinedIcon sx={{ color: '#3a7735' }} />
                                                                            <Typography lineHeight='1.5rem' variant="responsiveTextSize" > {t("copyLink")}</Typography>
                                                                        </Link>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>}
                                                        <RgeReqMobileCard request={regRequest} onCancelRegistration={onCancelRegistration} currentTab={currentTab} key={regRequest._id} />
                                                    </>)
                                            })
                                            }</>)
                                })}
                            </Container>
                        </>
                        : <NoHistoryBox text={t("noHistoryReg")} />
                    : <InactiveChildrenBox setAppBarTitle={setAppBarTitle} />
                }
            </>}
            {getHistoryReg.error && <InactiveChildrenBox setAppBarTitle={setAppBarTitle} />}
            <MessageBox messageBoxState={messageBoxState} setMessageBox={setMessageBox} />
        </>
    );
}
const SortLine = ({ setSorter, disabled }) => {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(1); // Set initial value to the code of the default option
    const options = [{ label: t("actDate"), code: 1 }, { label: t("actStatus"), code: 2 }];

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setSorter(selectedValue);
    };

    return (
        <Box display="flex" flexDirection="row" alignItems="center" paddingLeft="3rem" >
            <Typography>{t("orderTable")}</Typography>
            <Select
                disabled={disabled}
                sx={{ paddingLeft: "1rem" }}
                variant='standard'
                value={selectedOption}
                onChange={handleChange}
            >
                {options.map((option, i) => (
                    <MenuItem key={i} value={option.code}>{option.label}</MenuItem>
                ))}
            </Select>
        </Box >
    );
};
const RgeReqCell = ({ request, onCancelRegistration, currentTab }) => {
    const createDate = format(new Date(request.updatedAt), 'dd/MM/yy');
    const startDate = format(new Date(request.StartDateD), 'dd/MM/yy');
    const { t } = useTranslation();

    return (
        <TableRow sx={{ padding: '0 0.5rem' }} key={request.id}>
            {!currentTab && <MuiTableCell><Typography variant="responsiveTextSize">{request.Pupil_FirstName}</Typography></MuiTableCell>}
            <MuiTableCell><Typography variant="responsiveTextSize">{startDate}</Typography></MuiTableCell>
            <MuiTableCell><Typography variant="responsiveTextSize">{createDate}</Typography></MuiTableCell>
            <MuiTableCell><Typography fontWeight='600' variant="responsiveTextSize">{request.ActivName}</Typography></MuiTableCell>
            <MuiTableCell><Typography variant="responsiveTextSize">{!!request?.Payment_Method && request?.Payment_Method !== "0" && request?.Status_request !== "6" ? permanentInfo.paymentMethods[request.Payment_Method] : '-'}</Typography></MuiTableCell>
            <MuiTableCell sx={{ maxWidth: "7rem" }}>
                <Box display='flex' flexDirection="column">
                    <StatusChip status={request.Status_request} size='small' />
                    {request.Status_request !== "6" &&
                        <Link component='button' onClick={() => { onCancelRegistration(request) }} sx={{ color: '#3a7735 !important', fontFamily: "Alef,sans-serif !important" }} >
                            <Typography lineHeight='1.5rem' variant="responsiveTextSize">{request.Status_request === "4" ? t("cancelReg") : t("cancelReq")}</Typography>
                        </Link>}
                </Box>
            </MuiTableCell>
        </TableRow>
    )
}
const RgeReqMobileCard = ({ request, onCancelRegistration }) => {
    const createDate = format(new Date(request.updatedAt), 'dd/MM/yy');
    const startDate = format(new Date(request.StartDateD), 'dd/MM/yy');
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const onGenatareLink = (_id) => {
        const link = `${window.location.origin}/spl/${_id}`;
        navigator.clipboard.writeText(link);
        const key = enqueueSnackbar(t('copiedLink'), { variant: "success" });
        setTimeout(() => { closeSnackbar(key) }, 1500)
    }

    return (
        <Card sx={{ padding: '0 0.2rem', margin: "1rem 0", boxShadow: "none", borderBottom: "1px solid black", borderRadius: "0px" }} key={request.id}>
            <Box display='flex' flexDirection="row" alignItems='center' justifyContent='flex-start'>
                <Box display='flex' flexDirection="column" padding='1rem 0.5rem' alignItems='flex-start'>
                    <Box display='flex' flexDirection="column" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{request.Pupil_FirstName}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography variant="responsiveTextSize">{t('activDate')}:</Typography>
                        <Typography variant="responsiveTextSize">{startDate}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography variant="responsiveTextSize">{!!request?.Payment_Method && request?.Status_request !== "6" ? permanentInfo.paymentMethods[request.Payment_Method] : '-'}</Typography>
                    </Box>
                    {request.Status_request === "3" && <Box display='flex' flexDirection="column" alignItems='flex-start'>
                        <Link component='button' onClick={() => { onGenatareLink(request._id) }} visibility='hidden' sx={{ color: '#3a7735 !important', fontFamily: "Alef,sans-serif !important" }}>
                            <Typography lineHeight='1.5rem' variant="responsiveTextSize">{t("copyLink")}</Typography>
                        </Link>
                    </Box>}
                </Box>
                <Box display='flex' flexDirection="column" padding='1rem 0.5rem' alignItems='flex-start'>
                    <Box display='flex' flexDirection="column" alignItems='center'>
                        <Typography fontWeight='600' variant="responsiveTextSize">{request.ActivName}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>
                        <Typography variant="responsiveTextSize">{t('regDate')}:</Typography>
                        <Typography variant="responsiveTextSize">{createDate}</Typography>
                    </Box>
                    <Box display='flex' flexDirection="row" alignItems='center'>

                        <Box display='flex' flexDirection="row" alignItems='center'>
                            <StatusChip status={request.Status_request} size='small' />
                            {request.Status_request !== "6" &&
                                <Link component='button' onClick={() => { onCancelRegistration(request) }} padding="0 0.5rem !important" sx={{ color: '#3a7735 !important', fontFamily: "Alef,sans-serif !important" }}>
                                    <Typography lineHeight='1.5rem' variant="responsiveTextSize">{request.Status_request === "4" ? t("cancelReg") : t("cancelReq")}</Typography>
                                </Link>}
                        </Box>
                    </Box>
                    {request.Status_request === "3" && <Box display='flex' flexDirection="column">
                        <Link component='button' onClick={() => { onGenatareLink(request._id) }} sx={{ color: '#3a7735 !important', fontFamily: "Alef,sans-serif !important" }} >
                            <Typography lineHeight='1.5rem' variant="responsiveTextSize">{t("copyLink")}</Typography>
                        </Link>
                    </Box>}
                </Box>
            </Box>
        </Card>
    )
}
export default ParentRegsHist;