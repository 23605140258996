import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'raviger';
import useApi from '../../hooks/useApi';
//mui
import { makeStyles } from '@mui/styles';
import { Typography, LinearProgress, styled, Accordion, AccordionDetails, AccordionSummary, Button, Stack, Chip, Tooltip } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import AddIcon from '@mui/icons-material/Add';
//libs
import debouce from "lodash.debounce";
//components
import { InputStyle } from 'pages/ActivitiesReport';
import { HBox, VBox } from '../../assets/SharedComponents';
//api
import { searchInParent } from '../../api/api';
//data
import { permanentInfo } from '../../data/permanentInfo';

const useStyles = makeStyles((theme) => ({
    box: {
        width: '90% !important',
        marginTop: "1rem"
    },
    searchLine: {
        alignItems: 'center !important',
        margin: 'auto !important',
        marginBottom: '1rem !important'
    },
    accordion: {
        '&.MuiAccordion-root.Mui-expanded': {
            margin: '0px !important'
        },
        boxShadow: 'none !important'
    },
    familyAccordion: {
        width: '100% !important',
        background: '#e4f0da !important',
        minHeight: '40px !important',
        height: '40px !important',
        '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '40px !important',
        }
    },
    spaceBetween: {
        width: '100% !important',
        justifyContent: 'space-between !important',
    },
    familyAccordionTitle: {
        alignItems: 'center !important',
        fontSize: '0.8rem !important'
    },
    lastNameTitle: {
        marginRight: '0.3rem !important',
        textDecoration: "underline"
    },
    familyAccordionDetails: {
        backgroundColor: '#f9fdf7 !important'
    },
    classInfoLabel: {
        borderRadius: '3rem !important',
        padding: '0 0.3rem !important',
        backgroundColor: '#1a7d80 !important',
        color: '#fff !important',
        fontSize: '1rem !important',
        marginLeft: '0.5rem !important',
        height: 'fit-content !important'
    },
    textBold: {
        fontWeight: '700 !important',
        color: 'black !important',
        marginLeft: '0.5rem !important'
    },
    childName: {
        fontSize: '1.25rem !important',
        marginRight: "0.8rem  !important"
    },
    childDetails: {
        marginRight: "1.7rem  !important"
    },
    childId: {
        fontSize: '0.75rem !important',
        marginRight: "0.6rem  !important"
    },
    parentId: {
        fontSize: '0.75rem !important',
        marginRight: '0.6rem !important'
    },
    noResults: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        color: '#797979 !important'
    },
    addChildButton: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        margin: 'auto !important',
        borderRadius: '50px !important',
        width: '14.75rem !important'
    },
    addChildText: {
        "&:hover": {
            background: '#cee2cd !important',
        },
        alignItems: 'center !important',
        justifyContent: 'center !important',
        marginBottom: '1.1rem !important',
        borderRadius: '50px !important',
        width: '16.75rem !important',
        backgroundColor: '#3a7735 !important',
        color: "#ffffff !important"
    },
    addFamilyButton: {
        alignItems: 'center !important',
        justifyContent: 'center !important',
        backgroundColor: '#ffbb41 !important',
        margin: 'auto !important',
        position: 'sticky !important',
        bottom: '0 !important',
        marginTop: '1rem !important',
        borderRadius: '50px !important',
        width: '14.75rem !important',
        textTransform: "capitalize !important"
    },
    addFamilyText: {
        color: '#064601 !important',
        fontSize: '1.1rem !important',
        fontWeight: 'bold !important',
    },
    ActivChip: {
        "& .MuiChip-label": {
            display: 'flex',
            justifyContent: 'center',
        },
        marginLeft: '0.5rem !important',
        padding: '0 0.3rem !important',
        height: '1rem !important',
        width: '1rem !important',
        color: `${theme.palette.green.darker} !important`,
        backgroundColor: 'transparent !important',
        border: `1px solid ${theme.palette.green.darker} !important`
    },
}));
const SearchInput = styled(InputStyle)(({ theme }) => ({
    padding: '0 1rem',
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        height: '1.5rem',
        width: '25rem',
        [theme.breakpoints.down('xl')]: {
            width: '17rem',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '15rem',
            width: '100%',
            "& .MuiInputBase-input": {
                fontSize: '.8rem'
            }
        }
    }
}));

const SearchFamily = ({ tribe, addNewPupil, setDisabledLevel1, setDisabledLevel2, setDisabledLevel3, setFamily, tribeUser }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchResults, setSearchResults] = useState([]);
    const search = useApi(searchInParent);
    const [noMatchResults, setNoMatchResult] = useState(false);
    const [manyResults, setManyResults] = useState();

    const handleChange = async (e) => {
        try {
            const toSearch = e.target.value.trim();
            if (!!toSearch) {
                const data = await search.requestPromise(tribe?.UnitCode, toSearch);
                if (data?.d?.results?.length <= 10) {
                    setManyResults(false);
                    setSearchResults(data.d.results);
                    if (!data?.d?.results.length) {
                        setNoMatchResult(true);
                        setManyResults(false);
                    } else {
                        setNoMatchResult(false);
                    }
                } else {
                    setManyResults(true);
                }
            } else {
                setSearchResults([]);
                setNoMatchResult(false);
                setManyResults(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 750);
    }, [tribe]);
    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    }, []);
    const handleAddFamily = () => {
        setDisabledLevel1(true);
        setDisabledLevel2(false)
    }

    return (
        <VBox className={classes.box}>
            <HBox className={classes.searchLine} sx={{ justifyContent: tribeUser ? 'start' : '' }}>
                <SearchInput
                    inputRef={input => input && input.focus()}
                    placeholder={t('searchPlaceholder')}
                    onChange={e => debouncedResults(e)}
                    InputProps={{
                        endAdornment: (<SearchTwoToneIcon sx={{ color: '#3a7735' }} />)
                    }}
                />
            </HBox>
            {
                search.loading ?
                    <Stack spacing={2}>
                        <Typography align='center'>{t('searching')}</Typography>
                        <LinearProgress />
                    </Stack>
                    :
                    search.error &&
                    <Typography align='center'>{t('errorOccured')}</Typography>
            }
            {
                noMatchResults &&
                <VBox className={classes.noResults} sx={{ alignItems: tribeUser ? 'start' : '' }}>
                    <Typography>{t('noMatchResults')} </Typography>
                    <Typography style={{ fontWeight: 'bold' }}>{t('noMatchResults1')} </Typography>
                </VBox>
            }
            {
                manyResults ?
                    <VBox className={classes.noResults} sx={{ alignItems: tribeUser ? 'start' : '' }}>
                        <Typography>{t('manySearchResults')} </Typography>
                        <Typography style={{ fontWeight: 'bold' }}>{t('manySearchResults1')} </Typography>
                    </VBox>
                    :
                    searchResults.map((filterdFamily, i) => {
                        return <Family
                            key={filterdFamily.CardCode}
                            filterdFamily={filterdFamily}
                            addNewPupil={addNewPupil}
                            setDisabledLevel3={setDisabledLevel3}
                            setDisabledLevel1={setDisabledLevel1}
                            setFamily={setFamily}
                            tribeUser={tribeUser} />
                    })}
            {!tribeUser && <Button onClick={e => handleAddFamily()} endIcon={<ArrowBackIosOutlinedIcon />} className={`${classes.addFamilyText} ${classes.addFamilyButton}`} > {t('newFamily')}</Button>}
        </VBox>
    )
}

export const Family = ({ addNewPupil, filterdFamily, setDisabledLevel3, setDisabledLevel1, setFamily, tribeUser }) => {
    const [familyExpanded, setFamilyExpanded] = useState(true);
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAddPupil = () => {
        setDisabledLevel3(false);
        setDisabledLevel1(true);
        addNewPupil(filterdFamily.CardCode, filterdFamily.Parent1_LastName);
    }
    const handleEditFamily = (family) => {
        setFamily(family);
        navigate(`/tribeMyFamily/${family.CardCode}`);
    }
    return (
        <VBox>
            <Accordion square expanded={familyExpanded} className={classes.accordion}>
                <AccordionSummary className={classes.familyAccordion}>
                    <HBox className={classes.spaceBetween}>
                        <HBox className={classes.familyAccordionTitle}>
                            <GroupsOutlinedIcon />
                            {/* <Typography className={classes.parentId} >{t("pressToEdit1")}</Typography> */}
                            <Tooltip title={t("pressToEdit1")}>
                                <Typography className={classes.lastNameTitle} onClick={e => handleEditFamily(filterdFamily)}>{filterdFamily.Parent1_LastName}</Typography>
                            </Tooltip>
                            <Typography className={classes.parentId}>{filterdFamily.Parent1_ID}</Typography>
                        </HBox>
                        {familyExpanded ? <ExpandLessIcon onClick={e => setFamilyExpanded(false)} sx={{ color: '#3a7735' }} /> :
                            <ExpandMoreIcon onClick={e => setFamilyExpanded(true)} sx={{ color: '#3a7735' }} />}
                    </HBox>
                </AccordionSummary>
                {filterdFamily?.ChildrenList.results.map((child, i) => {
                    return (
                        <Children child={child} i={i} key={child.LicTradNum} />
                    )
                })}
                {!tribeUser && <HBox className={classes.addChildButton}>
                    <Button startIcon={<AddIcon />} className={classes.addChildText} onClick={e => handleAddPupil()}> {t('newPupil')} {filterdFamily.Parent1_LastName}</Button>
                </HBox>}
            </Accordion>
        </VBox>
    )
}
export const Children = ({ child, i }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <AccordionDetails style={{ background: i % 2 === 0 ? '#ffffff' : '#f9fdf7' }}>
            <HBox className={classes.spaceBetween}>
                <VBox>
                    <HBox alignItems={'center !important'}>
                        <PersonOutlineOutlinedIcon />
                        <Typography className={classes.childName}>{child.CardName}</Typography>
                    </HBox>
                    <HBox className={classes.childDetails} >
                        <Typography className={classes.childId}>{child.LicTradNum}</Typography>
                    </HBox>
                </VBox>
                <HBox alignItems={"center"}>
                    {child.Class !== '' &&
                        <Chip label={permanentInfo.childClasses[child.Class]} className={classes.ActivChip} />}
                    <Typography >{t('tribe')} {child.BPLName.slice(5)}</Typography>
                </HBox>
            </HBox>
        </AccordionDetails>
    )
}
export default SearchFamily;